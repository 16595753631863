
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Certifica-se de que padding e borda são incluídos na largura total do elemento */
}


:root {
  --primary-color: #304D73;
  --secondary-color: #00498d;
  --dark-primary-color: #e07800;
  --dark-secondary-color: #00315f;
  --light-color: #e9e9e9;
  --dark-color: #2c2c2c;
}

body {
  max-width: 100vw;
  font-family:"Roboto", sans-serif;
  font-weight: 400;
  color: var(--dark-color);
  font-style: normal;
}

a {
  text-decoration: none;
  color: unset;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.centered-text {
  display: inline-block;
  text-align: center;
  width: 100%;
}


/* START NAV */
.nav {
  background-color: #fff;
  padding: 1rem;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 0px 3px 3px #ebebeb;
  z-index: 1;
}

.nav .inner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
  margin-inline: auto;
  margin-top: -40px;
  margin-bottom: -55px;
}

.nav img{
  width:200px ;
}

.nav ul {
  display: flex;
  list-style: none;
  align-items: center;
  text-align: center;
  gap: 2rem;
}


.nav ul li:hover {
  border-bottom: 0.5px solid var(--dark-color);
  color: var(--dark-color);
}
 

.navs-icon-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.navs-icon-container svg {
  height: 1.5rem;
}

.navs-icon-container .shopping-cart {
  position: relative;
}

.navs-icon-container .shopping-cart .products-count {
  content: "";
  position: absolute;
  background-color: #f00;
  width: 1rem;
  height: 1rem;
  bottom: -8px;
  left: -8px;
  border-radius: 50%;
  color: #fff;
  padding: 0.7rem;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-input-container {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  border-bottom: 1px solid var(--dark-color);
  padding: 0.5rem;
  margin-right: 1rem;
}

.search-input-container input {
  border: none;
  outline: none;
}

.search-input-container svg {
  height: 1rem;
  color: #808080;
}

nav ul li:hover {
  transform: translateY(-4px);
  transition: all 0.2s;
  font-weight: bold;
}

.menu-button {
  display: none;
}

/* END NAVBAR */

/* START HEADER */
/* Header.css */
.header-slider {
  max-height: 250px; /* Ajuste esta altura conforme necessário */
  overflow: hidden;
  box-sizing: border-box;
  width: 100vw; /* Assegure que a largura ocupe toda a viewport */
}

.slider-item {
  display: flex;
  align-items: center;
  justify-content: center; /* Centraliza os itens do slide horizontalmente */
  height: 100%; /* Certifique-se de que os itens do slider ocupem a altura total */
  box-sizing: border-box;
}

.inner-content {
  display: flex;
  align-items: center;
  justify-content: center; /* Centraliza o conteúdo interno horizontalmente */
  height: 100%;
  width: 100%; /* Adicione largura 100% */
  box-sizing: border-box;
  
}

.left-side, .right-side {
  padding: 20px; /* Ajuste o padding conforme necessário */
  
}

.left-side h2 {
  margin: 0;
  font-size: 1.5rem; /* Ajuste o tamanho da fonte conforme necessário */
}

.left-side p {
  margin: 10px 0;
  font-size: 1rem; /* Ajuste o tamanho da fonte conforme necessário */
}

.ibilimp {
  max-height: 350px; /* Ajuste esta altura conforme necessário */
  width: auto;
  width: 100%;
}

.see-more-btn {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.see-more-btn:hover {
  background-color: #0056b3;
}

.see-more-btn span {
  margin-right: 10px;
}


/* END HEADER */

/* MEDIA : NAV E HEADER */
@media (max-width: 920px) {
  .nav h1 {
    font-size: 1.2rem;
  }

  header .inner-content {
    gap: 1rem;
    padding: 1.5rem;
    padding-top: 0rem;
  }

  header .left-side h2 {
    font-size: 1.8rem;
  }

  nav {
    position: absolute;
    top: 90px;
    left: 0;
    background-color: #fff;
    width: 100%;
    padding-bottom: 1rem;
    display: none;
  }

  nav.show {
    display: block;
  }

  nav ul {
    flex-direction: column;
  }

  nav ul li {
    text-align: center;
    padding: 0.8rem;
  }

  .menu-button {
    display: inline;
  }

  .nav img {
    width: 150px;
  }
  .search-input-container input{
    width: 80px;
  }
}

@media (max-width: 780px) {

  header .left-side h2{
    font-size: 4rem;
  }

  header .left-side p{
    font-size: 1.5rem;
  }
  header .inner-content {
    grid-template-columns: 1fr 1fr;
  }

  header .inner-content {
    padding-top: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
  }
}

@media (max-width: 420px) {
  .search-input-container input{
    width: 80px;
  }
  

  header .inner-content {
    padding-top: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
  }

  header .left-side h2{
    padding-top: 0rem;
    font-size: 2rem;
  }

  header .left-side p{
    font-size: 1.1rem;
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 1rem;

  }

  nav{
    top: 60px;
  }
  .nav img{
    width: 110px;
    padding: 5px;
    margin: 5px;
  }
  
  .product-list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }
}


/* START MAIN CONTENT */
.page-inner-content {
  max-width: 1280px;
  margin-inline: auto;
  padding-inline: 1rem;
}



.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, 170px);
  place-content: center;
  gap: 0.5rem;
  padding-bottom: 2rem;
}

/* Seletor de fragrâncias */
.fragrance-selector {
  margin-top: 10px;
  text-align: center;
}

.fragrance-selector label {
  display: block;
  margin-bottom: 5px;
}

.fragrance-selector select {
  width: 100%;
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
}


.product {
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;

}


.product.unavailable img {
  cursor: not-allowed !important;
}

.unavailable-overlay span {
  background-color:var(--secondary-color);
  border-radius: 4px;
  text-align: center;
  padding: 5px;
  margin: 5px;
}

.product .unavailable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  font-weight: bold;
  z-index: 1;
  text-align: center;
  border-radius: 8px;
  pointer-events: none; /* Certifique-se de que a sobreposição não interfira nos cliques */
}


.product .buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.product .name {
  font-weight: bold;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.product .price {
  margin-top: 0.4rem;
  font-size: large;
}

.product .price span {
  font-weight: bold;
}

.product .rate,
.testimonial .rate {
  color: var(--secondary-color);
  font-size: 1.3rem;
}

.product-image {
  transition: transform 0.3s;
}

.product-image:hover {
  transform: scale(1.5); /* Aumenta o tamanho em 10% */
}

.additional-images {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.price-container {
  background-color: #f0f0f0; /* Cor de fundo padrão */
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-size: large;
  font-weight: bolder; 
}

/* CSS para os controles de quantidade */

.quantity-controls {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.quantity-btn {
  background-color: #ddd;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 1.2em;
}

.quantity {
  margin: 0 10px;
  font-size: 1.2em;
}


/* CSS para o popup */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.expanded-image {
  max-width: 100%;
  max-height: 80vh; /* Use viewport height para limitar a altura */
  object-fit: contain; /* Garante que a imagem mantenha suas proporções */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 2em;
  color: #fff;
  cursor: pointer;
  padding: 10px;
}

.close-button:hover {
  color: #ddd;
}


/*End CSS para o popup */

.btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: 0.8rem;
  background-color: var(--primary-color); /* Utilize a variável de cor primária do seu projeto */
  color: #fff;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s;
  border: none;
  cursor: pointer;
}

.btn-icon:hover {
  background-color: #007bff; /* Cor de hover desejada */
}

.whatsapp-icon {
  width: 24px; /* Ajuste o tamanho conforme necessário */
  height: 24px; /* Ajuste o tamanho conforme necessário */
}

.btn-icon span {
  margin-left: auto; /* Empurra o texto para a direita */
}


.btn-icon:hover {
  background-color: var(--dark-primary-color);
}

.add-to-cart-btn {
  background-color: var(--secondary-color);
}

.add-to-cart-btn:hover {
  background-color: var(--dark-secondary-color);
}

.clear-cart-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: 0.8rem;
  background-color: var(--primary-color); /* Utilize a variável de cor primária do seu projeto */
  color: #fff;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s;
  border: none;
  cursor: pointer;
  margin-top: 1rem; /* Ajuste conforme necessário */
}

.clear-cart-btn:hover {
  background-color:  #e07800;
   /* Cor mais escura para efeito hover */
}


.section-title {
  padding-bottom: 2rem;
  margin-top: 3rem;
}

.section-title h3 {
  text-align: center;
  font-size: 1.8rem;
}

.section-title .underline {
  width: 150px;
  height: 2px;
  background-color: var(--primary-color);
  margin: 4px auto 0 auto;
}

p{
  text-align: center;
}

.product-variations {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.variation {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  width: 150px;
}

.variation img {
  width: 100%;
  height: auto;
}

.variation .price {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.variation button {
  background-color: var(--primary-color);
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.variation button:hover {
  background-color: var(--dark-primary-color);
}

/* START SIDEBAR */
.sidebar-cart {
  background-color: #fff;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  box-shadow: 5px 5px 50px var(--dark-color);
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  display: none;
}

.sidebar-cart::-webkit-scrollbar {
  display: none;
}

.sidebar-cart .sidebar-product {
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--light-color);
  justify-content: space-between;
  padding: 0.5rem;
  gap: 0.5rem;
}

.sidebar-cart .top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.sidebar-cart .top button,
.sidebar-cart .sidebar-product button {
  background-color: var(--dark-color);
  color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}

.sidebar-cart .sidebar-product button {
  width: 1rem;
  height: 1rem;
}

.sidebar-cart .sidebar-product img {
  height: 75px;
  transition: transform 0.3s;
}

.sidebar-cart .sidebar-product img:hover {
  transform: scale(1.1); /* Aumenta o tamanho em 10% */
}

.sidebar-cart .total-container {
  margin-block: 1rem;
}

.sidebar-cart .left-side {
  
  display: flex;
  gap: 0.5rem;
  
}

.sidebar-cart .left-side .details {
  display: flex;
  flex-direction: column;


}

.sidebar-cart .left-side .details h4 {
  margin: 0;
  
}

.sidebar-cart .left-side .details p {
  font-size: 0.8rem;
  text-transform: uppercase; /* Transformar texto em maiúsculas */
  background-color: rgb(201, 199, 199);
  border-radius: 3px;
  padding: 3px;
}

.sidebar-cart .quantity-control {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.svg-inline--fa.fa-xmark{
    width: 16px;
}

.sidebar-cart .quantity-control input {
  width: 55px;
  margin: 0 0.25rem;
  padding: 0.4rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  outline: none;
  transition: border-color 0.3s ease;
  
}

.sidebar-cart .quantity-control input:focus {
  border-color: #007bff;
}

.sidebar-cart .quantity-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #007bff;
  border: none;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s ease, color 0.3s ease;
  
}

.sidebar-cart .quantity-btn:hover {
  background: #0056b3;
}

.sidebar-cart .quantity-btn svg {
  width: 16px;
  height: 16px;
}

.sidebar-cart .right-side {
  display: flex;
  align-items: center;
  
}

.sidebar-cart.show {
  display: block;
}

.price-sum{
  text-align: center;
  background-color: #c4c4c4;
  padding: 5px;
  border-radius: 5px;
}

.total-container{
  text-align: center;
  background-color: #c4c4c4;
  padding: 5px;
  border-radius: 5px;
}

.payment-method {
  margin: 10px 0;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.payment-method .payment-title {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.payment-method .payment-option {
  display: block;
  margin-bottom: 5px;
}

.payment-method .payment-input {
  margin-right: 5px;
}

.payment-method .payment-label {
  margin-left: 5px;
}

.payment-method .change-needed {
  margin: 10px 0;
}

.payment-method .change-checkbox {
  margin-right: 5px;
}

.payment-method .change-input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.delivery-address {
  margin: 10px 0;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.delivery-address label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}


.delivery-input {
  width: 100%;
  padding: 8px;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.change-needed {
  margin: 10px 0;
}

.change-checkbox{
  margin-right: 5px;
}


.change-needed input[type="text"] {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
}




/* END SIDEBAR */


.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-logo {
  height: 50px;
}

.links-container ul {
  list-style: none;
  padding: 0;
}

.links-container ul li {
  display: inline;
  margin: 0 10px;
}

.links-container ul li a {
  color: #fff;
  text-decoration: none;
}

.social-container a {
  color: #fff;
  margin: 0 10px;
  font-size: 20px;
}

.footer-bottom {
  
  text-align: center;
  padding: 4px 0;
  background-color: #222;
  
}

.developer{
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 0.8rem;
  padding-top: 2px;
}

.scroll-to-top {
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 1000;
}

.scroll-to-top-button {
  background-color: #333;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: opacity 0.4s, visibility 0.4s;
}

.scroll-to-top-button:hover {
  background-color: #555;
}