.scroll-to-top {
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 1000;
  }
  
  .scroll-to-top-button {
    background-color: #333;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: opacity 0.4s, visibility 0.4s;
  }
  
  .scroll-to-top-button:hover {
    background-color: #555;
  }
  